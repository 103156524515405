import { Link, useLocation } from 'react-router-dom'
import icodevLogo from 'assets/logos/d2dlogo.svg'
// Train animation SVG components
// import { ReactComponent as SmokeTrain } from 'assets/icons/smoke-train.svg'
// import { ReactComponent as SmokeFreeTrain } from 'assets/icons/smoke-free-train.svg'
// import { ReactComponent as SmallSmokeTrain } from 'assets/icons/small-smoke-train.svg'
import terms from 'assets/terms'
import { PORTAL_URL } from 'config/config'
import { Paths } from 'types'
import { useRoles } from 'services'
import AccountMenu from './menu/MenuAccount'

import './TopBar.scss'

type Props = {
  appName: string;
}

export default function TopBar({ appName }: Props) {
  const { isBaremiste, isCat, isRcps } = useRoles()
  const location = useLocation()

  // Get the active path based on the current location and user roles
  const getActivePath = () => {
    // if path is '/', we need to attribute the right path based on user roles
    if (location.pathname === Paths.HOME) {
      if (isCat) return Paths.MISSIONS
      if (isRcps) return Paths.FLUX
      if (isBaremiste) return Paths.BAREMES
    } else {
      return location.pathname
    }

    return ''
  }

  // Displays available paths based on user roles in the right order
  const getPathsOrder = () => {
    const paths = []
    if (isCat) paths.push(Paths.MISSIONS)
    if (isRcps) paths.push(Paths.FLUX, Paths.MISSIONS)
    if (isBaremiste) paths.push(Paths.BAREMES, Paths.MISSIONS)

    return Array.from(new Set(paths))
  }

  return (
    <header>
      <div className="logos">
        <a className="flex-center" href={PORTAL_URL.url}>
          <img alt={appName} src={icodevLogo} />
        </a>
        <h1>{appName}</h1>
      </div>
      <nav>
        {getPathsOrder().map(path => (
          <Link
            key={path}
            to={path}
            className={getActivePath().includes(path) ? 'active' : 'inactive'}
          >
            {terms.Header.nav[path]}
          </Link>
        ))}
      </nav>
      {/*
        ----Train Animation----
      <div className="separation" />
        <div className="animate-logo">
          <SmokeFreeTrain className="locomotive image1" />
          <SmallSmokeTrain className="locomotive image2" />
          <SmokeTrain className="locomotive image3" />
        </div>
      <div className="separation" />
      */}
      <AccountMenu />
    </header>
  )
}
