import { ReactElement, useEffect, useState } from 'react'
import {
  FluxSignal,
  LotSignal,
  SarSignal,
  SectionPlanificationSignal,
  deleteTrame,
  getSectionPlanification,
  patchTrame,
  postTrame,
} from 'services'
import { Mission, PatchTrame, Planification } from 'types'
import { ImportModal, ModalSignal } from 'components'
import terms from 'assets/terms'
import { ModalType } from 'components/modals/ModalWrapper'
import TrameActions from './components/TrameActions'
import TrameDetail from './components/TrameDetail'
import TrameEdit from './components/TrameEdit'

import './Trames.scss'

interface Props {
  trame: Planification
  selectedEdit: string
  setSelectedEdit: (value: string) => void
}
export default function Trame({ trame, selectedEdit, setSelectedEdit }: Props): ReactElement {
  const flux = FluxSignal.value
  const sar = SarSignal.value
  const lot = LotSignal.value
  const [possibleOd, setPossibleOd] = useState<string[]>([])
  const [possibleJc, setPossibleJc] = useState<string[]>([])
  const [patchValue, setPatchValue] = useState<PatchTrame>({
    id: trame.id,
    mission: trame.lotsTronconsPlanifies[0]?.troncon.mission,
    // eslint-disable-next-line max-len
    origineDestination: `${trame.lotsTronconsPlanifies[0]?.troncon?.arretDepart?.lieu?.ngramme} - ${trame.lotsTronconsPlanifies[0]?.troncon?.arretArrivee?.lieu?.ngramme}`,
    jourCirculations: trame.lotsTronconsPlanifies?.map(item => item?.jourCirculation).join(' - '),
  })

  useEffect(() => {
    setPatchValue({
      id: trame.id,
      mission: trame.lotsTronconsPlanifies[0]?.troncon.mission,
      // eslint-disable-next-line max-len
      origineDestination: `${trame.lotsTronconsPlanifies[0]?.troncon?.arretDepart?.lieu?.ngramme} - ${trame.lotsTronconsPlanifies[0]?.troncon?.arretArrivee?.lieu?.ngramme}`,
      jourCirculations: trame.lotsTronconsPlanifies?.map(item => item?.jourCirculation).join(' - '),
    })
  }, [trame.lotsTronconsPlanifies[0]?.troncon.mission])

  const handleChange = <T, >(key: string, value: T) => {
    switch (key) {
      case 'mission':
        setPatchValue({
          ...patchValue,
          mission: value as Mission,
          origineDestination: '',
          jourCirculations: '',
        })
        break
      default:
        setPatchValue({
          ...patchValue,
          [key]: value,
        })
        break
    }
  }

  const handlePatchOrPost = () => {
    if (!patchValue.mission || !patchValue.origineDestination || !patchValue.jourCirculations) return
    setSelectedEdit('')
    if (patchValue.id === 'new') {
      postTrame(flux.id, sar.id, lot.id, patchValue)
      return
    }
    patchTrame(flux.id, sar.id, lot.id, patchValue.id, patchValue)
  }

  const handleEdit = (id: string) => {
    setSelectedEdit(id)
    getSectionPlanification(patchValue.mission?.id, lot.id)
  }

  const handleDelete = (deleteMethod: () => Promise<void | {error: unknown}>) => {
    deleteMethod().then(() => {
      ModalSignal.value = null
    })
  }

  const openModalDelete = (
    message: string,
    onDelete: () => Promise<void | {error: unknown}>,
  ) => {
    ModalSignal.value = (
      <ImportModal
        title={message}
        handleClickConfim={() => handleDelete(onDelete)}
        type={ModalType.WARNING}
        textButton={terms.Common.continue}
      />
    )
  }

  useEffect(() => {
    setPossibleOd(SectionPlanificationSignal.value.map(item => item.origineDestination))
    setPossibleJc(SectionPlanificationSignal.value.find(
      item => item?.origineDestination === patchValue.origineDestination,
    )?.joursCirculation || [])
  }, [SectionPlanificationSignal.value, patchValue.origineDestination, patchValue.mission?.id])

  useEffect(() => {
    if (
      (trame.lotsTronconsPlanifies[0]?.troncon?.mission?.id !== patchValue.mission?.id)
      && patchValue.mission?.id
    ) {
      getSectionPlanification(patchValue.mission?.id, lot.id)
    }
  }, [patchValue.mission?.id])

  return (
    <div className="trame-wrapper">
      <TrameActions
        onEdit={selectedEdit === trame.id}
        setOnEdit={() => handleEdit(trame.id)}
        handlePatch={handlePatchOrPost}
        disabled={!Object.values(patchValue).every(item => item)}
        onOpenDelete={() => openModalDelete(
          terms.Pages.Flux.Lot.Trame.modalDelete,
          () => deleteTrame(flux.id, sar.id, lot.id, trame.id),
        )}
      />
      <div className="detail">
        {
          trame.lotsTronconsPlanifies?.map(item => (
            <TrameDetail key={item.id} data={item} />
          ))
        }
      </div>
      {
        (selectedEdit === trame.id) && (
          <div className="edit">
            <TrameEdit
              patchValue={patchValue}
              handleChange={handleChange}
              possibleOd={possibleOd}
              possibleJc={possibleJc}
              trameSelected={trame}
            />
          </div>
        )
      }
    </div>
  )
}
