import { ReactElement } from 'react'

import './Tooltip.scss'

interface Props {
  message: string
}

export default function Tooltip({ message }: Props): ReactElement {
  return (
    <div className="tooltip">
      <div className="message">
        <span>
          {message}
        </span>
      </div>
    </div>
  )
}
