import { ReactElement, useState } from 'react'
import { Button, ButtonStyle, ModalSignal, TextInput } from 'components'
import terms from 'assets/terms'
import { postMission, verifyMissionId } from 'services'
import { useNavigate } from 'react-router-dom'
import { Mission, Paths } from 'types'
import { saveToSession } from 'utils'

import './Style.scss'

export default function CreateMission(): ReactElement {
  const navigate = useNavigate()
  const [missionId, setMissionId] = useState<string>('')
  /**
   *  Reset the signal to close the modal
   */
  const resetSignal = () => {
    ModalSignal.value = null
    setMissionId('')
  }

  const createMission = () => {
    postMission(missionId).then(response => {
      if ('error' in response) return
      if (response) {
        resetSignal()
        saveToSession('missionId', (response as Mission).id)
        navigate(`${Paths.MISSIONS}/${(response as Mission).missionId}`)
      }
    })
  }
  return (
    <div className="create-mission">
      <h2>
        {terms.Pages.Missions.Modal.CreateMission.title}
      </h2>
      <span className="subtitle">
        {terms.Pages.Missions.Modal.CreateMission.label}
      </span>
      <div className="mission-id">
        <TextInput
          onChange={setMissionId}
          errorMessage={verifyMissionId(missionId) ? '' : terms.Pages.Mission.Form.Errors.missionLength}
        />
      </div>
      <div className="buttons">
        <Button
          text={terms.Common.cancel}
          style={ButtonStyle.borderLess}
          onClick={resetSignal}
        />
        <Button
          text={terms.Common.validate}
          disabled={!verifyMissionId(missionId)}
          onClick={createMission}
        />
      </div>
    </div>
  )
}
