import terms from 'assets/terms'
import { ReactElement, useState } from 'react'
import { FluxSignal, filtersApi, patchFlux, deleteFlux } from 'services'
import { Filter, FluxState, FluxType, Paths } from 'types'
import {
  AddClientModal, AutocompleteInput, Loader, ModalSignal, RadioInput, TextInput, Button, ButtonStyle,
  ImportModal,
} from 'components'
import { ModalClosableSignal, ModalType } from 'components/modals/ModalWrapper'
import { DisabledModalButtonSignal } from 'components/modals/import/ImportModal'
import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '@mui/icons-material/ErrorOutline'
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg'
import { ReactComponent as UnlockIcon } from 'assets/icons/unlock.svg'

import { useNavigate } from 'react-router-dom'

import './FluxHome.scss'
import { debounce } from 'lodash'

export default function FluxHome(): ReactElement {
  const flux = FluxSignal.value
  const [currentFlux, setCurrentFlux] = useState<FluxType>(flux)
  const [locked, setLocked] = useState(true)
  const navigate = useNavigate()

  const handleOpenDeleteModal = (key?: string) => {
    DisabledModalButtonSignal.value = false

    switch (key) {
      case 'alert':
        ModalSignal.value = (
          <ImportModal
            title={terms.Pages.Flux.Delete.Alert.title}
            handleClickConfim={() => {
              deleteFlux(currentFlux.id).then(() => {
                handleOpenDeleteModal('success')
              })
            }}
            type={ModalType.WARNING}
            textButton={terms.Common.confirm}
          />
        )
        break

      case 'success':
        ModalClosableSignal.value = false
        ModalSignal.value = (
          <ImportModal
            title={terms.Pages.Flux.Delete.deleteSuccess}
            type={ModalType.SUCCESS}
            handleClickConfim={() => {
              navigate(Paths.FLUX)
              DisabledModalButtonSignal.value = false
              ModalSignal.value = null
              ModalClosableSignal.value = true
            }}
            textButton={terms.Common.close}
          />
        )
        break

      default:
        handleOpenDeleteModal('alert')
        break
    }
  }

  // eslint-disable-next-line comma-spacing
  const handleChange = <T,>(key: string, value: T) => {
    switch (key) {
      case 'estCharge':
        setCurrentFlux({
          ...currentFlux,
          estCharge: value as boolean,
        })
        patchFlux(
          currentFlux.id,
          {
            estCharge: value as boolean,
          },
        )
        break
      default:
        setCurrentFlux({
          ...currentFlux,
          [key]: value,
        })

        if (!value) return

        patchFlux(
          currentFlux.id,
          {
            [key]: value,
          },
        )
        break
    }
  }

  const handleAddClient = (client: Filter) => {
    handleChange('client', client)
  }

  const handleModalAdd = () => {
    ModalSignal.value = (<AddClientModal handleClientAdded={handleAddClient} />)
  }

  if (!FluxSignal.value) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    )
  }

  const debouncedHandleChange = debounce(handleChange, 500)

  return (
    <div className="flux-home">
      <div className={`banner${locked ? '' : ' active'}`}>
        <InfoIcon />
        <span>
          {terms.Pages.Flux.form.modifyKeyWarning}
        </span>
      </div>
      <h3>
        {terms.Pages.Flux.form.title}
      </h3>
      <div className="form">
        <TextInput
          label={terms.Pages.Flux.form.key}
          bindedValue={currentFlux?.cleFlux || ''}
          onChange={val => debouncedHandleChange('cleFlux', val)}
          onClick={() => setLocked(!locked)}
          Icon={locked ? <LockIcon /> : <UnlockIcon />}
          disabled={locked}
        />
        <div className="client-input">
          <AutocompleteInput<Filter>
            minSearchLength={0}
            label={terms.Pages.Flux.form.client}
            apiEndpoint={filtersApi.CLIENTS}
            resultIdKey="id"
            resultLabelKey="libelle"
            defaultValue={currentFlux?.client?.libelle || ''}
            defaultSelected={currentFlux?.client || null}
            onChange={val => handleChange('client', val)}
          />
          <button
            className="add-client"
            type="button"
            onClick={handleModalAdd}
          >
            <AddIcon />
          </button>
        </div>
        <div className="row">
          <div>
            <TextInput
              label={terms.Pages.Flux.form.origin}
              bindedValue={currentFlux?.origine || ''}
              onChange={val => debouncedHandleChange('origine', val)}
            />
            <TextInput
              label={terms.Pages.Flux.form.destination}
              bindedValue={currentFlux?.destination || ''}
              onChange={val => debouncedHandleChange('destination', val)}
            />
          </div>
          <div>
            <span>{terms.Pages.Flux.form.state}</span>
            <RadioInput
              label={FluxState.loaded}
              defaultValue={currentFlux?.estCharge}
              onChange={() => handleChange('estCharge', true)}
            />
            <RadioInput
              label={FluxState.empty}
              defaultValue={!currentFlux?.estCharge}
              onChange={() => handleChange('estCharge', false)}
            />
          </div>
        </div>
      </div>
      <div className="buttons">
        <Button
          text={terms.Common.delete}
          onClick={() => handleOpenDeleteModal('confirm')}
          style={ButtonStyle.delete}
        />
      </div>
    </div>
  )
}
