import terms from 'assets/terms'
import { ReactElement, useEffect, useState } from 'react'
import {
  FluxSignal, LotSignal, LotsSignal, SarSignal, TramesSignal, deleteLot, patchLot,
} from 'services'
import { weeklyDays } from 'utils'
import { Button, ButtonStyle, Loader, SelectInput, TextInput } from 'components'
import { debounce } from 'lodash'
import Trames from '../trame/Trames'

import './FluxLot.scss'

interface Props {
  onOpenDelete: (message: string, onDelete: () => Promise<void | {error: unknown}>) => void
}

export default function FluxLot({ onOpenDelete }: Props): ReactElement {
  const lot = LotSignal.value
  const lots = LotsSignal.value
  const sar = SarSignal.value
  const flux = FluxSignal.value
  const trames = TramesSignal.value

  const [currentLot, setCurrentLot] = useState(lot)

  const handleChange = <T, > (key: string, value: T) => {
    setCurrentLot({
      ...currentLot,
      [key]: value,
    })

    patchLot(flux.id, sar.id, currentLot.id, {
      [key]: value,
    })
  }

  useEffect(() => {
    setCurrentLot(lot)
  }, [lot])

  if (!lot || !sar || !currentLot || !lots || !trames) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    )
  }

  const numLot = LotsSignal.value.findIndex(l => l.id === lot.id) + 1

  const debouncedHandleChange = debounce(handleChange, 500)

  return (
    <div className="flux-lot">
      <div className="info">
        <div>
          <h3>{terms.Pages.Flux.Lot.sarInfoTitle}</h3>
          <div className="sar-info">
            <div>
              <span>
                {terms.Pages.Flux.Lot.form.length}
              </span>
              <p>
                {sar?.longueur || 0}
              </p>
            </div>
            <div>
              <span>
                {terms.Pages.Flux.Lot.form.mass}
              </span>
              <p>
                {sar?.poids || 0}
              </p>
            </div>
            <div>
              <span>
                {terms.Pages.Flux.Lot.form.circulationRegime}
              </span>
              <ul>
                {
                  sar?.regimeCirculation.map(day => (
                    <li key={day}>{day}</li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
        <div>
          <h3>{`${terms.Pages.Flux.Lot.lotInfoTitle} ${numLot}`}</h3>
          <div className="form">
            <TextInput
              label={terms.Pages.Flux.Lot.form.length}
              defaultValue={currentLot?.longueur?.toString() || ''}
              bindedValue={currentLot?.longueur.toString() || ''}
              onChange={val => debouncedHandleChange('longueur', parseInt(val, 10))}
            />
            <TextInput
              label={terms.Pages.Flux.Lot.form.mass}
              defaultValue={currentLot?.poids?.toString() || ''}
              bindedValue={currentLot?.poids.toString() || ''}
              onChange={val => debouncedHandleChange('poids', parseInt(val, 10))}
            />
            <div>
              <SelectInput
                label={terms.Pages.Flux.Lot.form.routingOffset}
                defaultValue={currentLot?.decalageAcheminementLot?.toString() || ''}
                onChange={val => handleChange<number>('decalageAcheminementLot', parseInt(val as string, 10))}
                options={weeklyDays.map((_, index) => ({ label: index.toString(), value: index.toString() }))}
              />
              <div className="days">
                {
                  currentLot?.joursDepart?.map(day => <span key={day}>{day}</span>)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Trames />
      <div className="buttons">
        <Button
          text={terms.Common.delete}
          onClick={() => onOpenDelete(
            terms.Pages.Flux.Lot.Modal.deleteMessage,
            () => deleteLot(flux.id, sar.id, currentLot.id),
          )}
          style={ButtonStyle.delete}
        />
      </div>
    </div>
  )
}
