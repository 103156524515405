import terms from 'assets/terms'
import { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  MissionSignal, patchMission, filtersApi, verifyMissionId, normalizeErrorMessages, deleteMission,
} from 'services'
import { Bfr, Egs, Mission, Paths } from 'types'
import {
  AutocompleteInput, Button, ButtonStyle, CheckInput, ImportModal, Loader, ModalSignal, TextInput,
} from 'components'
import BannerInfo from 'components/banner/BannerInfo'
import { debounce } from 'lodash'
import { ModalClosableSignal, ModalType } from 'components/modals/ModalWrapper'
import { DisabledModalButtonSignal } from 'components/modals/import/ImportModal'

import './MissionHome.scss'

export default function MissionHome(): ReactElement {
  const navigate = useNavigate()
  const mission = MissionSignal.value
  const [currentMission, setCurrentMission] = useState<Mission>(mission)
  const [outBfr, setOutBfr] = useState<boolean>(false)

  // ----------- UseEffect -----------
  useEffect(() => {
    if (!mission) return
    setCurrentMission(mission)
  }, [mission])

  useEffect(() => {
    setOutBfr(currentMission.codeBfr === null || false)
  }, [MissionSignal.value])

  if (!mission) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    )
  }

  // ----------- Functions -----------

  /**
   * Function to handle change on input fields and update the mission
   * @param value
   * @param key
  */
  function handleChange<T>(value: T, key: string) {
    switch (key) {
      case 'missionId':
        setCurrentMission({
          ...currentMission,
          missionId: value as string,
        })
        if (verifyMissionId(value as string) && value !== mission.missionId) {
          const prevUpdatedId = mission.missionId
          patchMission({
            id: currentMission.id,
            missionId: value as string,
          }).then(() => {
            if (prevUpdatedId !== value) {
              window.location.href = `/missions/${value}`
            }
          })
        }
        break
      default:
        setCurrentMission({
          ...currentMission,
          [key]: value,
        })
        patchMission({
          id: currentMission.id,
          [key]: value,
        })
        break
    }
  }

  /**
   * Debounced function to handle change on input fields and update the mission
   * @param value
   * @param key
   */
  const debouncedHandleChange = debounce(handleChange, 500)

  /**
   * Function to open the delete modal
  */
  const handleOpenDeleteModal = (key?: string) => {
    DisabledModalButtonSignal.value = false
    switch (key) {
      case 'firstAlert':
        ModalSignal.value = (
          <ImportModal
            title={terms.Pages.Mission.Delete.FirstAlert.title}
            info={[terms.Pages.Mission.Delete.FirstAlert.info]}
            handleClickConfim={() => {
              handleOpenDeleteModal('secondAlert')
              DisabledModalButtonSignal.value = true
            }}
            type={ModalType.WARNING}
            textButton={terms.Common.continue}
          />
        )
        break
      case 'secondAlert':
        ModalSignal.value = (
          <ImportModal
            title={terms.Pages.Mission.Delete.SecondAlert.title}
            info={[
              terms.Pages.Mission.Delete.SecondAlert.info,
              terms.Pages.Mission.Delete.SecondAlert.additionalInfo,
            ]}
            handleClickConfim={() => {
              deleteMission(mission.id).then(() => {
                handleOpenDeleteModal()
              })
            }}
            type={ModalType.WARNING}
            textButton={terms.Common.continue}
          >
            <div>
              <CheckInput
                label={terms.Pages.Mission.Delete.SecondAlert.additionalInfo}
                defaultValue={false}
                onChange={check => {
                  DisabledModalButtonSignal.value = !check
                }}
              />
            </div>
          </ImportModal>
        )
        break
      default:
        ModalClosableSignal.value = false
        ModalSignal.value = (
          <ImportModal
            title={terms.Pages.Mission.Delete.deleteSuccess}
            type={ModalType.SUCCESS}
            handleClickConfim={() => {
              navigate(Paths.MISSIONS)
              DisabledModalButtonSignal.value = false
              ModalSignal.value = null
              ModalClosableSignal.value = true
            }}
            textButton={terms.Common.close}
          />
        )
        break
    }
  }

  return (
    <div className="mission-home">
      <BannerInfo messages={normalizeErrorMessages(currentMission.errorMessage)} />
      <h3>
        {terms.Pages.Mission.Form.Edit.title}
      </h3>
      <div className="form">
        <TextInput
          label={terms.Pages.Mission.Form.Edit.mission}
          bindedValue={currentMission.missionId}
          onChange={value => debouncedHandleChange(value, 'missionId')}
          clearable
          type="string"
          required
          errorMessage={!verifyMissionId(currentMission.missionId) ? terms.Pages.Mission.Form.Errors.missionLength : ''}
        />
        <CheckInput
          label={terms.Pages.Mission.Form.Edit.missionSecurity}
          defaultValue={currentMission.missionDeSecurite}
          onChange={() => handleChange<boolean>(!currentMission.missionDeSecurite, 'missionDeSecurite')}
        />
        <AutocompleteInput<Egs>
          label={terms.Pages.Mission.Form.Edit.egs}
          apiEndpoint={filtersApi.EGS}
          defaultValue={currentMission.egs ? `${currentMission.egs?.lrEgs} - ${currentMission.egs?.libelle}` : ''}
          defaultSelected={currentMission.egs}
          resultIdKey="id"
          resultLabelKey="libelle"
          resultLabelKeyOptional="lrEgs"
          minSearchLength={0}
          onChange={value => handleChange<Egs>(value as Egs, 'egs')}
          required
        />
        <CheckInput
          label={terms.Pages.Mission.Form.Edit.outBfr}
          defaultValue={outBfr}
          onChange={() => setOutBfr(!outBfr)}
        />
        <AutocompleteInput<Bfr>
          label={terms.Pages.Mission.Form.Edit.bfrCode}
          defaultValue={currentMission.codeBfr
            ? `${currentMission.codeBfr?.numero} - ${currentMission.codeBfr?.libelle}`
            : ''}
          defaultSelected={currentMission.codeBfr}
          apiEndpoint={filtersApi.BFR}
          resultIdKey="id"
          resultLabelKey="libelle"
          resultLabelKeyOptional="numero"
          params={{ egs: currentMission.egs?.libelle || '' }}
          minSearchLength={0}
          onChange={value => handleChange<Bfr>(value as Bfr, 'codeBfr')}
          disabled={!!outBfr}
          required={!outBfr}
        />
      </div>
      <div className="buttons">
        <Button
          text={terms.Common.delete}
          onClick={() => handleOpenDeleteModal('firstAlert')}
          style={ButtonStyle.delete}
        />
      </div>
    </div>
  )
}
