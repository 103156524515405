import terms from 'assets/terms'
import {
  AutocompleteInput, Button, ButtonStyle, Loader, SelectInput, TextInput,
} from 'components'
import Timeline from 'components/diagram/Timeline'
import { ReactElement, useEffect, useState } from 'react'
import {
  MissionSignal,
  VariantSectionSignal,
  VariantSectionsSignal,
  VariantSignal,
  deleteVariantSection,
  filtersApi,
  patchVariantSection,
} from 'services'
import { Filter, OnOpenDelete, Option, SectionDetail } from 'types'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { debounce } from 'lodash'
import { v4 as uuidV4 } from 'uuid'
import StationForm from './StationForm'

import './MissionSection.scss'

export default function MissionSection({ onOpenDelete }: OnOpenDelete): ReactElement {
  const mission = MissionSignal.value
  const variant = VariantSignal.value
  const section = VariantSectionSignal.value
  const sections = VariantSectionsSignal.value
  const [currentSection, setCurrentSection] = useState<SectionDetail>(section)

  useEffect(() => {
    if (!section) return
    setCurrentSection(section)
  }, [section])

  if ((!variant || !section || !mission || !sections || !currentSection)) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    )
  }

  const title = `
    ${terms.Pages.Mission.SideMenu.variant}
    ${variant.jourHebdo.map(day => day.slice(0, 2)).join('')} - 
    ${terms.Pages.Mission.Form.Section.section(false)} 
    ${sections.findIndex(item => item.id === section.id) + 1}/${sections.length}
  `

  function handleChange<T>(key: string, value: T, secondKey?: string) {
    if (value === undefined || value === null) return
    setCurrentSection({
      ...currentSection,
      [key]: secondKey ? {
        ...currentSection[key],
        [secondKey]: value,
      } : value,
    })

    patchVariantSection(
      mission.id,
      variant.id,
      {
        id: currentSection.id,
        [key]: secondKey ? {
          ...currentSection[key],
          [secondKey]: value,
        } : value,
      },
    )
  }

  const debouncedHandleChange = debounce(handleChange, 500)

  return (
    <div className="variant-section">
      <div className="header">
        <Timeline
          selectedId={section.id}
          data={sections}
        />
      </div>
      <div className="form-container">
        <h3>
          {title}
        </h3>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="forms">
            <div>
              <h4>
                {terms.Pages.Mission.Form.Section.generalInfo}
              </h4>
              <div className="form">
                <div className="sillon">
                  <span>
                    {terms.Pages.Mission.Form.Section.numSillon}
                  </span>
                  <ul>
                    {
                      currentSection.numerosSillon.map(sillon => (
                        <li key={uuidV4()}>
                          {sillon}
                        </li>
                      ))
                    }
                  </ul>
                </div>
                <TextInput
                  label={terms.Pages.Mission.Form.Section.capacityWeight}
                  type="number"
                  bindedValue={currentSection.capacitePoids.toString()}
                  onChange={val => debouncedHandleChange('capacitePoids', parseInt(val, 10))}
                  required
                />
                <TextInput
                  label={terms.Pages.Mission.Form.Section.capacityLength}
                  type="number"
                  bindedValue={currentSection.capaciteLongueur.toString()}
                  onChange={val => debouncedHandleChange('capaciteLongueur', parseInt(val, 10))}
                  required
                />
                <SelectInput
                  label={terms.Pages.Mission.Form.Section.direction}
                  options={[
                    { label: 'AZ', value: 'AZ' },
                    { label: 'ZA', value: 'ZA' },
                  ] as Option[]}
                  defaultValue={currentSection.sensReleve}
                  onChange={val => debouncedHandleChange('sensReleve', val as string)}
                />
                <AutocompleteInput<Filter>
                  label={terms.Pages.Mission.Form.Section.tube}
                  defaultValue={currentSection.tube?.libelle}
                  defaultSelected={currentSection.tube}
                  apiEndpoint={filtersApi.TUBES}
                  resultIdKey="id"
                  resultLabelKey="libelle"
                  minSearchLength={0}
                  onChange={val => handleChange<Filter>('tube', val as Filter)}
                />
              </div>
            </div>
            <StationForm
              type="arretDepart"
              autocompleteValue={currentSection.arretDepart?.lieu}
              timeValue={currentSection.arretDepart?.heureDepart}
              selectValue={currentSection.arretDepart?.decalageDepart?.toString()}
              handleChange={(key, val, secondKey) => handleChange(key, val, secondKey)}
              days={currentSection.joursDepart}
              required
            />
            <StationForm
              type="arretArrivee"
              autocompleteValue={currentSection.arretArrivee?.lieu}
              timeValue={currentSection.arretArrivee?.heureArrivee || ''}
              selectValue={currentSection.arretArrivee?.decalageArrivee?.toString()}
              handleChange={(key, val, secondKey) => handleChange(key, val, secondKey)}
              days={currentSection.joursArrivee}
              required
            />
          </div>
        </LocalizationProvider>
        <div className="buttons">
          <Button
            text={terms.Common.delete}
            onClick={() => onOpenDelete(
              terms.Pages.Mission.Form.Section.Modal.deleteText,
              () => deleteVariantSection(
                mission.id,
                variant.id,
                currentSection.id,
              ),
            )}
            style={ButtonStyle.delete}
          />
        </div>
      </div>
    </div>
  )
}
