// import { useParams } from 'react-router-dom'
import { ImportModal, Loader, ModalSignal } from 'components'
import { ReactElement, useEffect, useState } from 'react'
import { FluxSignal, getFlux, getSars } from 'services'
import { FluxElement } from 'types'
import { ModalType } from 'components/modals/ModalWrapper'
import terms from 'assets/terms'
import FluxHeader from './header/FluxHeader'
import FluxSar from './sar/FluxSar'
import FluxHome from './home/FluxHome'
import FluxSideMenu from './side/FluxSideMenu'
import FluxLot from './lot/FluxLot'

import './FluxPage.scss'

export default function FluxPage() {
  // Todo : get fluxId from params and fetch data
  // const params = useParams<{ fluxId: string }>()
  const [elementSelected, setElementSelected] = useState<FluxElement>(FluxElement.HOME)
  const fluxId = sessionStorage.getItem('fluxId')

  useEffect(() => {
    if (!fluxId) return
    getFlux(fluxId)
    getSars(fluxId)
  }, [])

  if (!FluxSignal.value) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    )
  }

  const handleDelete = (deleteMethod: () => Promise<void | {error: unknown}>) => {
    deleteMethod().then(() => {
      setElementSelected(FluxElement.HOME)
      ModalSignal.value = null
    })
  }

  const openModalDelete = (
    message: string,
    onDelete: () => Promise<void | {error: unknown}>,
  ) => {
    ModalSignal.value = (
      <ImportModal
        title={message}
        handleClickConfim={() => handleDelete(onDelete)}
        type={ModalType.WARNING}
        textButton={terms.Common.continue}
      />
    )
  }

  const getFluxElement = (): ReactElement => {
    switch (elementSelected) {
      case FluxElement.SAR:
        return <FluxSar onOpenDelete={openModalDelete} />
      case FluxElement.LOT:
        return <FluxLot onOpenDelete={openModalDelete} />
      default:
        return <FluxHome />
    }
  }

  return (
    <div
      id="fluxPage"
      className="page"
    >
      <FluxHeader />
      <div className="content">
        <FluxSideMenu
          setElementSelected={setElementSelected}
          elementSelected={elementSelected}
        />
        {getFluxElement()}
      </div>
    </div>
  )
}
