import { ReactElement, useState } from 'react'
import { Button, ButtonStyle, CheckInput, ModalSignal, TextInput } from 'components'
import { v4 as uuidV4 } from 'uuid'
import terms from 'assets/terms'
import { sortDays, weeklyDays } from 'utils'

import './AddLotMissionModal.scss'
import { NewLot } from 'types'

interface Props {
  subTitle: string
}

const DEFAULT_NEW_LOT: NewLot = {
  libelle: '',
  poids: 0,
  longueur: 0,
  nombreWagons: 0,
  jourHebdo: [],
}

export default function AddLotMissionModal({ subTitle }: Props): ReactElement {
  const [newLot, setNewLot] = useState<NewLot>(DEFAULT_NEW_LOT)

  /**
   * Function to handle the change of the input
   * @param key string
   * @param value T
  */
  const handleChange = <T, > (key: string, value: T) => {
    setNewLot({
      ...newLot,
      [key]: value,
    })
  }

  /**
   * Function to close the modal and reset the new lot
  */
  const handleClose = () => {
    setNewLot(DEFAULT_NEW_LOT)
    ModalSignal.value = null
  }

  /**
   * Create the new lot
  */
  const createLot = () => {
    // todo Create the new lot
  }

  const disabled = !Object.values(newLot).every(val => {
    if (Array.isArray(val)) {
      return !!val.length
    }
    return !!val
  })

  return (
    <div className="add-lot-mission">
      <div className="title">
        <h2>{terms.Pages.Missions.Modal.CreateLot.title}</h2>
        <h3>{subTitle}</h3>
      </div>
      <div className="form">
        <div className="inputs">
          <TextInput
            label={terms.Pages.Missions.Modal.CreateLot.name}
            bindedValue={newLot.libelle}
            onChange={val => handleChange('libelle', val)}
          />
          <TextInput
            label={terms.Pages.Missions.Modal.CreateLot.weight}
            bindedValue={newLot.poids.toString()}
            onChange={val => handleChange('poids', val)}
          />
          <TextInput
            label={terms.Pages.Missions.Modal.CreateLot.length}
            bindedValue={newLot.longueur.toString()}
            onChange={val => handleChange('longueur', val)}
          />
          <TextInput
            label={terms.Pages.Missions.Modal.CreateLot.wagons}
            bindedValue={newLot.nombreWagons.toString()}
            onChange={val => handleChange('nombreWagons', val)}
          />
        </div>
        <div className="days-input">
          {weeklyDays.map(day => (
            <CheckInput
              key={uuidV4()}
              label={day}
              defaultValue={newLot.jourHebdo.includes(day)}
              onChange={() => handleChange<string[]>(
                'jourHebdo',
                newLot.jourHebdo.includes(day)
                  ? newLot.jourHebdo.filter(jour => jour !== day)
                  : newLot.jourHebdo.concat(day).sort(sortDays),
              )}
            />
          ))}
        </div>
      </div>
      <div className="buttons">
        <Button
          text={terms.Common.cancel}
          style={ButtonStyle.borderLess}
          onClick={handleClose}
        />
        <Button
          text={terms.Common.save}
          onClick={createLot}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
