import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { SentryConfigured } from '@osrdata/app_core'
import { store, persistor } from './reducers/store'
import 'assets/css/constants.scss'
import App from './App'

SentryConfigured()

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
)
