import CheckIcon from '@mui/icons-material/CheckOutlined'
import terms from 'assets/terms'
import { ReactElement } from 'react'
import {
  normalizeErrorMessages, exportSars, FluxSignal, SarsSignal, SarsToImportSignal, TaskSignal,
} from 'services'
import { ExportStatus, Paths } from 'types'
import { getStatusColor } from 'utils'
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg'
import { Button, ButtonStyle, CheckInput, ImportModal, LoadingModal } from 'components'
import { ButtonSize } from 'components/button/Button'
import { ModalSignal, ModalType } from 'components/modals/ModalWrapper'
import { DisabledModalButtonSignal } from 'components/modals/import/ImportModal'

import './FluxHeader.scss'

export default function FluxHeader(): ReactElement {
  const handleCheck = (id: string) => (checked: boolean) => {
    if (checked) {
      SarsToImportSignal.value = [...SarsToImportSignal.value, id]
      DisabledModalButtonSignal.value = false
    } else {
      SarsToImportSignal.value = SarsToImportSignal.value.filter(item => item !== id)
      if (!SarsToImportSignal.value.filter(item => item !== id).length) {
        DisabledModalButtonSignal.value = true
      }
    }
  }

  const renderSarImportModalChild = () => (
    <div className="import-sar-modal-children">
      {SarsSignal.value.map((sar, index) => (
        <div key={sar.id} className="check-input-wrapper">
          <CheckInput
            label={`SAR ${index + 1}`}
            defaultValue
            onChange={handleCheck(sar.id)}
            disabled={sar.statutExport === ExportStatus.SUCCESS}
          />
          {sar.statutExport === ExportStatus.SUCCESS && (
          <span
            className="success-message flex-center"
            style={{ color: getStatusColor(sar.statutExport).chip }}
          >
            <CheckIcon />
            {terms.Pages.Flux.Import.alreadyImported}
          </span>
          )}
        </div>
      ))}
    </div>
  )

  const handleOpenModal = (type: ModalType) => {
    DisabledModalButtonSignal.value = false
    switch (type) {
      case ModalType.WARNING:
        SarsToImportSignal.value = SarsSignal.value.map(
          sar => sar.statutExport !== ExportStatus.SUCCESS && sar.id,
        ).filter(Boolean)
        ModalSignal.value = (
          <ImportModal
            title={terms.Pages.Flux.Import.sarTitle}
            info={[terms.Pages.Flux.Import.infoNoReimport]}
            type={ModalType.WARNING}
            textButton={terms.Common.continue}
            handleClickConfim={() => { exportSars(FluxSignal.value.id, handleOpenModal) }}
          >
            {renderSarImportModalChild()}
          </ImportModal>
        )
        break
      case ModalType.SUCCESS:
        ModalSignal.value = (
          <ImportModal
            title={terms.Pages.Flux.Import.success}
            type={ModalType.SUCCESS}
            textButton={terms.Common.close}
          />
        )
        break
      case ModalType.ERROR:
        ModalSignal.value = (
          <ImportModal
            title={terms.Pages.Flux.Import.failure(TaskSignal.value?.importSuccessNumber || 0)}
            info={normalizeErrorMessages(TaskSignal.value?.traceback).map(item => item.message) || []}
            type={ModalType.ERROR}
            textButton={terms.Common.close}
          />
        )
        break
      case ModalType.LOADING:
        ModalSignal.value = (
          <LoadingModal message={terms.Pages.Flux.Import.loading} />
        )
        break
      default:
        break
    }
  }

  return (
    <div className="flux-header">
      <div className="title">
        <Button
          text={terms.Common.back}
          icon={<ArrowLeft />}
          style={ButtonStyle.borderLessWhite}
          onClick={() => { window.location.href = Paths.FLUX }}
        />
        <div>
          {`${terms.Pages.Flux.title} ${FluxSignal.value.cleFlux || ''}`}
        </div>
        <span style={{ backgroundColor: getStatusColor(FluxSignal.value.statutExport).chip }} />
      </div>
      <div className="import-button">
        <Button
          text={terms.Common.RusImport}
          onClick={() => { handleOpenModal(ModalType.WARNING) }}
          disabled={FluxSignal.value.statutExport === ExportStatus.SUCCESS}
          style={ButtonStyle.primary}
          size={ButtonSize.xlarge}
        />
      </div>
    </div>
  )
}
