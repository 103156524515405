import { useEffect, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'

import './CheckInput.scss'

type Props = {
  label: string
  defaultValue: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}

export default function CheckInput({
  label,
  defaultValue,
  onChange,
  disabled = false,
}: Props) {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChange = () => {
    onChange(!value)
    setValue(!value)
  }

  const generateId = uuidV4()
  return (
    <div className="checkbox input-wrapper">
      <input
        id={generateId}
        type="checkbox"
        checked={value}
        onChange={handleChange}
        disabled={disabled}
      />
      <label htmlFor={generateId}>{label}</label>
    </div>
  )
}
