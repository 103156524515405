import { ReactElement, useEffect } from 'react'
import { signal } from '@preact/signals-react'

import './ModalWrapper.scss'

/**
 * Modal types
*/
export enum ModalType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  LOADING = 'loading',
}

/**
 * Signal used to communicate with modals
 * which are responsible for displaying/hiding the modal
*/
export const ModalSignal = signal<React.ReactNode>(undefined)

/**
 * Signal used to communicate with modals
 * Boolean signal to check if modal can be reset on outside click
*/
export const ModalClosableSignal = signal<boolean>(true)

export default function ModalWrapper(): ReactElement {
  const resetSignal = () => {
    if (ModalClosableSignal.value) ModalSignal.value = undefined
  }

  useEffect(() => () => {
    // Reset signal when unmounting modal component
    resetSignal()
  }, [])

  return (
    <div className={`modal-wrapper ${ModalSignal.value ? ' displayed' : ''}`}>
      <div className="modal">
        {ModalSignal.value}
      </div>
      <div className="background" onClick={resetSignal} />
    </div>
  )
}
