import { get } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ErrorMessage, TaskType } from 'types'
import { catchable } from 'utils'

//* --------------- List API URL task ---------------
export const taskApi = {
  TASK: (id: string) => `/apt/task-results/${id}`,
}

//* --------------- Signal to store task ---------------
export const TaskSignal = signal<TaskType>(null)

//* --------------- Update task to the signal ---------------
/**
 * Update task to the signal
 * @param task - taskType
*/
export const updateTask = (task: TaskType) => {
  TaskSignal.value = task
}

//* --------------- API task ---------------
/**
 * fetch task
 * @param taskId - task id
*/
export const getTask = (taskId: string) => catchable(async () => {
  TaskSignal.value = null
  const response = await get<TaskType>(taskApi.TASK(taskId))
  updateTask(response)

  return response
})

/**
 * Normalize error messages to an array of ErrorMessage objects
 * @param messages - error message or an array of error messages
 *
 * @returns ErrorMessage[]
 */
export const normalizeErrorMessages = (messages: ErrorMessage[] | string): ErrorMessage[] => {
  if (messages && typeof messages === 'string') {
    return [
      {
        message: terms.Common.unknownError,
        httpCode: 400,
      },
    ]
  }
  return messages as ErrorMessage[] || []
}
