import './SwitchInput.scss'

type Props = {
  label?: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

export default function SwitchInput({ label, checked, onChange }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked)
    }
  }
  return (
    <div className="switch-input">
      <label htmlFor={label}>{label}</label>
      <input id={label} type="checkbox" checked={checked} onChange={handleChange} />
    </div>
  )
}
